@import '@/styles/colors.module';

.warning {
  padding: 8px 0;
  border: none;
  background: $gray;
  font-size: 14px;

  &Text {
    color: $light_gray;
  }

  &Border {
    border-top: 1px solid $gold;
    border-bottom: 1px solid $gold;
  }

  .button {
    line-height: 16px !important;
    height: fit-content !important;
  }
}